import React from 'react';
import { Hero, SEO, CaseStudyPreviews, Clients, Link } from 'components';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import { graphql } from 'gatsby';

const OurWorkPage = ({ data, location }) => {
  const { windowWidth } = useWindowSize();
  return (
    <>
      <SEO
        pageSpecificTitle="Our Work | Digital & Healthcare Marketing"
        pageSpecificDescription="View our most recent projects in healthcare, brand strategy, digital marketing and virtual events here."
      />
      <Hero>
        <HeroContent>
          <h1>
            This is
            <br />
            our work
          </h1>
          <div>
            <p>
              Take a look around and see how we have helped our clients achieve results using
              digital marketing campaigns, brand strategy, website development, graphic design and
              more.
            </p>
            {windowWidth > 1100 && (
              <Link href="all-case-studies" offset={-77} style={{ marginTop: '1.05rem' }}>
                Explore
              </Link>
            )}
          </div>
        </HeroContent>
      </Hero>
      <AllCaseStudies id="all-case-studies">
        <div>
          <CaseStudyPreviews caseStudies={data.cases.edges} pathname={location.pathname} />
        </div>
      </AllCaseStudies>
      <Clients />
    </>
  );
};

const HeroContent = styled.div`
  div {
    display: flex;
    flex-direction: column;
  }

  p {
    max-width: 23em;
    margin: 1.45em 0 1.75em;
  }

  @media screen and (min-width: 48rem) {
    h1 {
      position: relative;
      right: 2px;
    }

    p {
      margin: 1.675em 0 3.45em;
    }
  }

  @media screen and (min-width: 68.75rem) {
    display: flex;
    justify-content: flex-start;
    margin: 0 auto 7.2rem;
    max-width: 1180px;

    h1 {
      display: inline-block;
      flex-shrink: 0;
    }

    div {
      margin-left: 11rem;
    }

    p {
      margin: 1.675em 0 2.15em;
    }
  }
`;

const AllCaseStudies = styled.section`
  background-color: var(--ax-beige);
  padding: 1.875rem 0;

  > div {
    grid-column: 2 / 3;

    @media screen and (min-width: 48rem) {
      grid-column: 1 / -1;
      margin: 0 1.25rem;
    }

    @media screen and (min-width: 76.875rem) {
      margin: 0 3rem;
    }

    @media screen and (min-width: 114.75em) {
      max-width: 108.75rem;
      width: 100%;
      margin: 0 auto;
    }
  }
`;

export const WorkQuery = graphql`
  query ourWorkQuery {
    cases: allSanityCaseStudy(
      filter: { publicFacingCaseStudy: { eq: true } }
      sort: { fields: order, order: DESC }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          company
          whatWeDid
          previewImage {
            alt
            asset {
              gatsbyImageData(width: 870, layout: CONSTRAINED, placeholder: BLURRED, formats: AUTO)
            }
          }
        }
      }
    }
  }
`;

export default OurWorkPage;
